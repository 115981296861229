@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,800,800i);
.validation-errors {
  font-size: 13px;
  color: #d00247;
}

.fs-13 {
  font-size: 13px !important;
}

.progress-small {
  height: 8px;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 10px;
}

/* icons position fix */
.ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
  top: -7px !important;
}

.steps-action {
  margin-top: 24px;
}

.btn-tron-ghost {
  font-weight: 600;
  color: #166245 !important;
  background-color: transparent;
  border-radius: 20px;
  font-size: 0.9em;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: All 0.3s ease;
  border: 1px solid #166245;
}

span.nav-link {
  font-size: 14.4px;
}

.bg-transparent {
  background-color: transparent !important;
}

.loading-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.small-loading-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #2d7157;
}

.button-distance {
  display: flex;
  justify-content: space-around;
}

.m-t-sm {
  margin-top: 10px;
}

.card-box {
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 5px;
  cursor: pointer;
}

.card-box-no-pointer {
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 5px;
}

.card-box-default {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
}

.card-box-danger {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px #dc3545;
}

.m-b-xs {
  margin-bottom: 5px;
}

#chart {
  height: 440px;
  width: 100%;
}

#chart.pointer-on-bars .dxc-series rect {
  cursor: pointer;
}

.button-container {
  text-align: center;
  height: 34px;
  position: absolute;
  top: 30px;
  left: 15px;
}
/*@ CSS Document */
body .container-bg {
  font: 400 16px "Open Sans", sans-serif;
  color: #666;
  background: #fff;
}

/*---------------------------- Start S I T E --------------------------*/

a,
a:hover,
a:focus,
a:active {
  color: #166245;
}

/*@ header */
header.header-auth {
  background-color: #166245;
  color: #fff;
}
header.header-auth .container {
  padding-top: 10px;
  padding-bottom: 10px;
}
header.header-auth a,
header.header-auth a:hover,
header.header-auth a:focus,
header.header-auth a:active {
  color: #fff;
}

.form-control.tron-input:focus {
  color: #495057;
  background-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(54, 167, 125, 0.25);
}

header.header-auth .btn {
  font-size: 0.9em;
}

/*@ navbar-brand */
.auth.navbar-brand {
  width: 50px;
  height: 20px;
  background: url(/assets/img/sprite.png) no-repeat -20px -20px;
  margin: 0;
  padding: 0;
}
.auth.navbar-brand span {
  display: none;
}
.auth-site-desc {
  font-size: 0.85em;
  margin-left: 10px;
  margin-top: 5px;
  display: block;
}

/*@ logo-login */
.logo-login {
  width: 120px !important;
  height: auto !important;
  margin: 50px auto 50px auto;
}

/*@ wrap-form-login */
.wrap-form-login {
  display: block;
}
.wrap-form-login label {
  color: #166245;
  font-weight: 700 !important;
}
.wrap-form-login .form-control {
  border-radius: 30px;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}
.wrap-form-login .form-group {
  margin-bottom: 20px;
}
.wrap-form-login .checkbox {
  margin-bottom: 10px;
  padding-top: 10px;
  font-size: 0.85em;
}
.wrap-form-login .checkbox label {
  color: #7d7d7d !important;
  font-weight: 400 !important;
}
.wrap-form-login .checkbox input {
  margin-right: 6px;
  vertical-align: middle;
}

/*@ btn-tron */
.btn-tron {
  font-weight: 500;
  color: #fff;
  background-color: #166245;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}
.btn-tron:hover,
.btn-tron:focus,
.btn-tron:active {
  color: #fff !important;
  background-color: #0c422d !important;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

/*@ links-footer */
ul.links-footer {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;
  text-align: right;
  font-size: 0.85em;
}
ul.links-footer li {
  display: block;
  margin-bottom: 5px;
}

/*@ texto-footer */
.texto-footer {
  color: #c8c8c8;
  font-size: 0.65em;
  text-align: center;
  margin-bottom: 50px;
}

/*@ vert-align-banners */
section.auth.wrap .col-xl-4 {
  background: url(/assets/img/bg-login.jpg) no-repeat left top;
  background-size: cover;
  padding-top: 40px;
  padding-bottom: 40px;
}

/*@ wrap-banner-login */
.wrap-banner-login {
  display: block;
}
.wrap-banner-login:first-child {
  margin-bottom: 40px;
}

/*@ footer */
div.auth {
  color: #999;
  display: block;
}
.auth-copyright {
  padding-top: 10px;
  /* padding-bottom: 10px; */
  font-size: 0.8em;
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  /*@ vert-align-banners */
  section.auth.wrap .col-xl-4 {
    background: none;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  body .container-bg {
    background: url(/assets/img/bg-login.jpg) no-repeat left top;
    background-size: 60% auto;
  }

  .container-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .vert-align-banners {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .vert-align-banners .wrap-banner-login {
  }

  .wrap-form-login {
  }

  .logo-login {
    margin: 100px auto 50px auto;
  }

  /*@ header */
  header.header-auth {
    position: absolute;
    top: 25px;
    left: 0;
    background-color: transparent;
    z-index: 500;
  }

  /*@ footer */
  div.auth {
    color: #fff;
    position: absolute;
    bottom: 0px;
    left: 20px;
    z-index: 500;
  }
}

/*--------------------------------------- Tratamento para telas de alta resolução ---------------------------------------*/

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 120dpi) {
  .auth.navbar-brand {
    background-image: url(/assets/img/sprite@2x.png);
    background-size: 600px 600px;
  }
}

.responsivando {
  max-width: 100%;
  margin: 0 auto;
  height: auto !important;
}
.max-responsivando {
  width: 100% !important;
  margin: 0 auto;
  height: auto !important;
}

/*
Theme Name: Tron Área do Cliente
Author: Maxio
Author URI: http://www.maxio.com.br/
Description: Tema desenvolvido para o projeto Tron Área do Cliente.
Version: 1.0
*/

/*@ CSS Document */
body .app-container {
    display: flex;
    flex-direction: column;
    font: 400 16px 'Open Sans', sans-serif;
    color: #666;
    background: #f3f3f4;
}

a,
a:hover,
a:focus,
a:active {
    color: #166245;
}

.app.wrap {
    flex: 1 0 auto;
}

.app-container {
    min-height: 100vh;
}

input {
    font-weight: 200;
}

label {
    font-weight: 400 !important;
}

/*@ hx */
h1,
h2,
h3,
h4,
h5,
h6,
fieldset legend {
    margin: 0;
    padding: 0;
    line-height: 1em;
    font-weight: 800;
}

h1 {
    font-size: 1.5em;
    color: #9e9e9e;
    font-weight: 400;
    margin-bottom: 30px;
}

h1 strong {
    font-weight: 800;
    display: block;
}

/*---------------------------- Start S I T E --------------------------*/

/*@ header */
header.app {
    background-color: #166245;
    color: #fff;
}

header.app .container {
    padding-top: 10px;
    padding-bottom: 10px;
}

header.app a,
header.app a:hover,
header.app a:focus,
header.app a:active {
    color: #fff;
}

header.app .btn {
    font-size: 14.4px;
}

.change-contract {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.textwidget {
    text-align: justify;
    font-size: 14;
    color: #323232;
}

/*@ navbar-brand */
.navbar-brand {
    width: 50px;
    height: 20px;
    background: url(/assets/img/logos-produtos.png) no-repeat -20px -20px;
    margin: 0;
    padding: 0;
}

.navbar-brand span {
    display: none;
}

.site-desc {
    font-size: 0.85em;
    margin-left: 10px;
    margin-top: 5px;
}

/*@ btns header */
.btn-perfil:before {
    content: '';
    width: 20px;
    height: 19px;
    background: url(/assets/img/logos-produtos.png) no-repeat -120px -20px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
}

div.nav-link {
    font-size: 14.4px;
    cursor: pointer;
}

.btn-retornar:before {
    content: '';
    width: 20px;
    height: 19px;
    background: url(/assets/img/logos-produtos.png) no-repeat -150px -20px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
}

.btn-conta,
.btn-conta:hover,
.btn-conta:focus {
    color: #fff;
}

.btn-conta:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(54, 167, 125, 0.25);
}

.btn-conta:before {
    content: '';
    /* width: 17px; */
    height: 16px;
    /* background: url(/assets/img/logos-produtos.png) no-repeat -90px -20px; */
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
}

/*@ dropdown-menu */
.dropdown-menu {
    background-color: #166245;
    border-radius: 0;
    border: none;
    box-shadow: none;
    padding-top: 15px;
    padding-bottom: 15px;
}

.dropdown-item {
    color: #fff !important;
    font-size: 0.8em;
    padding-top: 8px;
    padding-bottom: 8px;
}

.dropdown-item:hover {
    color: #fff !important;
    background-color: #243930 !important;
}

/*@ wrap */
section.app.wrap {
}

section.app.wrap .container {
    padding-bottom: 15px;
}

/*@ box-acessar-meus-sistemas */
.box-acessar-meus-sistemas {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1.1em;
    font-size: 2em;
    text-align: center;
}

.box-acessar-meus-sistemas small {
    font-size: 0.6em;
    letter-spacing: 7px;
}

.box-acessar-meus-sistemas strong {
    color: #166245;
    display: block;
}

.box-acessar-meus-sistemas strong span {
    color: #3c3c3c;
    display: block;
}

.box-acessar-meus-sistemas .btn-tron {
    font-size: 0.5em;
    text-transform: none;
}

/*@ box-video-youtube */
.box-video-youtube {
    display: block;
    margin-bottom: 30px;
    background-color: #e7efec;
}

/*@ box-avisos */
.box-avisos {
    background-color: #e7efec;
    padding: 30px;
    margin-bottom: 30px;
}

.box-avisos p {
    font-size: 0.75em;
}

.box-avisos h4 {
    color: #166245;
    font-size: 1em;
    margin-bottom: 10px;
    line-height: 1.3em;
}

.box-produtos {
    display: block;
    width: 100%;
    padding: 30px;
    margin-bottom: 35px;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
    background-color: #ffffff;
}

.box-produtosleft {
    text-align: left;
}

.box-produtosright {
    float: right;
}

.wrap-produtos {
    padding-top: 30px;
}

.wrap-grid {
    padding-top: 0px !important;
}

/*@ box-destaque */
.box-destaque {
    display: block;
    width: 100%;
    border: 1px solid #166245;
    padding: 30px;
    text-align: center;
    margin-bottom: 35px;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
    background-color: #ffffff;
}

.box-destaque:hover {
    cursor: 'pointer';
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

.box-destaque span {
    display: block;
    margin: 0 auto 10px auto;
    background-image: url(/assets/img/logos-produtos.png);
    background-repeat: no-repeat;
    width: 150px;
    height: 100px;
}

.box-destaque h2 {
    color: #166245;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 15px;
}

.box-destaque p {
    color: #8d8d8d;
    line-height: 1.6em;
    font-size: 0.85em;
    margin-bottom: 15px;
}

.box-destaque a {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-weight: 500;
    color: #fff;
    background-color: #166245;
    border-radius: 20px;
    font-size: 1.1em;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

.box-destaque a:hover,
.box-destaque a:focus,
.box-destaque a:active {
    text-decoration: none;
    color: #fff !important;
    background-color: #0c422d !important;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

.box-destaque.box-tron-smart span {
    background-position: -0px -55px;
}

.box-destaque.box-tron-laris span {
    background-position: -180px -55px;
}

.box-destaque.box-tron-cte span {
    background-position: -380px -55px;
}

.box-destaque.box-tron-connect span {
    background-position: -24px -160px;
}

.box-destaque.box-tron-auditor span {
    background-position: -190px -150px;
}

.box-destaque.box-tgc span {
    background-position: -400px -140px;
}

.box-destaque.box-tron-nfe span {
    background-position: -205px -250px;
}

.box-destaque.box-tron-box span {
    background-position: -405px -250px;
}

.box-destaque.box-tron-metrics span {
    background-position: -5px -350px;
}

/*@ btn-tron */
.btn-tron-app {
    font-weight: 500;
    color: #fff !important;
    background-color: #166245;
    border-radius: 20px;
    font-size: 0.9em;
    margin-top: 10px;
    margin-right: 4px;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
    cursor: pointer;
}

.btn-tron-conf-app {
  font-weight: 500;
  color: #fff !important;
  background-color: #166245;
  border-radius: 20px;
  font-size: 1em !important;
  margin-top: 10px;
  margin-right: 4px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
  cursor: pointer;
}

.btn-tron-app:hover,
.btn-tron-app:focus,
.btn-tron-app:active {
    color: #fff !important;
    background-color: #0c422d !important;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

.btn-tron-border {
    font-weight: 500;

    border: 1px solid #0c422d !important;
    border-radius: 20px;
    font-size: 0.9em;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

.btn-tron-border:hover,
.btn-tron-border:focus,
.btn-tron-border:active {
    color: #fff !important;
    background-color: #0c422d !important;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

.btn-base {
    margin: 20px;
}

/*@ banner-publicidade */
.banner-publicidade {
    display: block;
    margin-bottom: 30px;
}

.banner-publicidade img {
    width: 100% !important;
    height: auto !important;
}

/*@ table-eventos */
.table-eventos {
    border: 1px solid #166245;
    margin-bottom: 30px;
}

.table-eventos thead tr th {
    background-color: #166245;
    color: #fff;
    border: none;
    font-weight: 600;
    font-size: 1.1em;
}

.table-eventos tbody tr td {
    color: #8d8d8d;
    font-size: 1em;
    vertical-align: middle;
    border-color: #166245;
    border-left: 1px solid #166245;
    color: #666;
}

.table-eventos tbody tr td.td-inscrevase {
    border-left: none;
}

.table-eventos tbody tr td.td-data {
    text-align: left;
}

.table-eventos tbody tr td.td-data p {
    display: block;
    color: #166245;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.72em;
}

.table-eventos tbody tr td.td-hora {
    text-align: center;
}

.table-eventos tbody tr:hover td {
    background-color: #f7f7f7;
}

.table-eventos a.tootip {
    color: #666;
    text-decoration: none;
}

.table-eventos tbody tr td span.online {
    color: #166245;
    font-weight: 600;
}

table.table-dados thead tr th {
    padding: 10px 0 !important;
    border: none;
    border-bottom: 1px solid #ddd;
}

table.table-dados tbody tr td {
    padding: 10px 0 !important;
    font-size: 0.9em !important;
    vertical-align: middle;
    border: none;
}

table.table-dados tbody tr td span.status-pago,
table.table-dados tbody tr td span.status-vigente {
    color: #166245;
}

table.table-dados tbody tr td span.status-vencido,
table.table-dados tbody tr td span.status-finalizado {
    color: #fa6969;
}

table.table-dados tbody tr td strong {
    font-size: 1.1em;
    color: #166245;
    font-weight: 600;
}

table.table-dados tbody tr td strong.total-vencido {
    color: #fa6969;
}

table.table-dados tbody tr.line-sep td {
    border-top: 1px solid #ddd;
}

/*@ article */
article h1 {
    color: #166245;
    font-size: 2.25em;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 10px;
}

article h2 {
    color: #2b2b2b;
    font-size: 1.4em;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.09em;
    margin-bottom: 40px;
}

/*@ wrap-table */
fieldset.wrap-fieldset {
    display: block;
    border: 1px solid #166245;
    margin-top: 30px;
    margin-bottom: 50px;
}

fieldset.wrap-fieldset legend {
    font-weight: 800;
    font-size: 1em;
    background-color: #166245;
    display: block;
    color: #fff;
    padding: 1em;
    float: left;
    clear: both;
}

fieldset.wrap-fieldset .inner-fieldset {
    display: block;
    padding: 35px;
    float: left;
    clear: both;
    width: 100%;
}

.wrap-first-table {
    display: block;
    padding: 0 30px;
}

.wrap-first-table table thead tr th {
    font-weight: 800 !important;
}

fieldset.wrap-fieldset table {
    width: 100%;
}

fieldset.wrap-fieldset table thead tr th {
    font-weight: 600;
    color: #166245;
    padding: 0px;
    vertical-align: middle;
}

fieldset.wrap-fieldset table tbody tr td {
    font-size: 1em;
    color: #666;
    padding: 0;
}

fieldset.wrap-fieldset table thead tr th a.texto-link:before {
    content: '▲';
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 5px;
    display: inline-block;
}

fieldset.wrap-fieldset table thead tr th a.texto-link.collapsed:before {
    content: '▼';
}

.item-accordion {
    background-color: #f4f4f4;
    border-radius: 7px;
    padding: 15px 30px;
    margin-top: 20px;
    display: block;
}

.item-accordion p {
    line-height: 1.6em;
    font-size: 0.9em;
}

/*@ lista-sidebar */
ul.lista-sidebar {
    margin: 0;
    padding: 10px 0;
    list-style: none;
    border-radius: 5px;
    margin-bottom: 30px;
    display: block;
    background-color: #166245;
}

ul.lista-sidebar li {
    display: block;
    padding: 0 20px;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

ul.lista-sidebar li:hover {
    background-color: #093222;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

ul.lista-sidebar li a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.9em;
    display: block;
    padding: 10px 0;
    border-top: 1px solid #12543a;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

ul.lista-sidebar li:hover a {
    border-color: transparent;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

ul.lista-sidebar li:first-child a {
    border: none;
}

ul.lista-sidebar li a:before {
    content: '';
    width: 32px;
    height: 32px;
    background-image: url(/assets/img/logos-produtos.png);
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

ul.lista-sidebar li.ls-home a:before {
    background-position: -20px -160px;
}

ul.lista-sidebar li.ls-financeiro a:before {
    background-position: -60px -160px;
}

ul.lista-sidebar li.ls-tron-laris a:before {
    background-position: -100px -160px;
}

ul.lista-sidebar li.ls-atualizacoes a:before {
    background-position: -140px -160px;
}

ul.lista-sidebar li.ls-tron-help a:before {
    background-position: -180px -160px;
}

ul.lista-sidebar li.ls-fale-com-o-diretor a:before {
    background-position: -220px -160px;
}

ul.lista-sidebar li.ls-loja-tron a:before {
    background-position: -260px -160px;
}

ul.lista-sidebar li.ls-contratos a:before {
    background-position: -300px -160px;
}

body.page-atualizacoes ul.lista-sidebar li.ls-atualizacoes,
body.page-tron-laris ul.lista-sidebar li.ls-tron-laris,
body.page-financeiro ul.lista-sidebar li.ls-financeiro,
body.page-fale-com-o-diretor ul.lista-sidebar li.ls-fale-com-o-diretor,
body.page-contratos ul.lista-sidebar li.ls-contratos {
    background-color: #093222;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

/*@ banner-lateral */
.banner-lateral {
    display: block;
    margin-bottom: 30px;
}

.banner-lateral img {
    width: 100% !important;
    height: auto !important;
}

/*@ wrap-form */
.wrap-form {
    margin-top: 50px;
}

.wrap-form label {
    color: #166245;
    font-weight: 600 !important;
}

.wrap-form .form-group {
    margin-bottom: 30px;
}

.wrap-form .form-control {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    /*border-radius: 30px;*/
    font-size: 1em;
}

.wrap-form textarea.form-control {
    resize: none;
}

.wrap-form .btn.btn-tron {
    font-size: 1.3em;
    border-radius: 30px;
}

/*@ footer */
footer.app {
    background-color: #166245;
    color: #fff;
    flex-shrink: 0;
    width: 100%;
}

.footer-grid {
    background-color: #166245;
    color: #fff;
    position: relative !important;
    bottom: 0;
    width: 100%;
}

footer.app .container {
    padding-top: 15px;
    padding-bottom: 15px;
}

.app-copyright {
    font-size: 0.7em;
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    /*@ box-acessar-meus-sistemas */
    .box-acessar-meus-sistemas {
        font-size: 2.5em;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    h1 {
        margin-bottom: 50px;
    }

    header.app {
        /* position: fixed; */
        width: 100%;
        z-index: 500;
    }

    section.app.wrap {
        padding-top: 15px;
    }

    /*@ box-destaque */
    .box-destaque {
        padding: 50px;
    }

    /*@ box-acessar-meus-sistemas */
    .box-acessar-meus-sistemas {
        padding: 25px;
    }

    .box-acessar-meus-sistemas .btn-tron {
        font-size: 0.35em;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 45px;
    }

    /*@ box-video-youtube */
    .box-video-youtube {
        height: 190px;
    }

    /*@ box-avisos */
    .box-avisos {
        min-height: 190px;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .vert-align-banners {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/*--------------------------------------- Tratamento para telas de alta resolução ---------------------------------------*/

@media only screen and (-webkit-min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dpi) {
    .navbar-brand,
    .btn-perfil:before,
    .btn-conta:before,
    .box-destaque span,
    ul.lista-sidebar li a:before {
        /*background-image: url(images/logos-produtos.png);*/
        background-size: 600px 600px;
    }
}

/*@ ------------------------------ Customizações ------------------------------ */

/*@ actions */
.position-relative {
    position: relative;
}

.responsivando {
    max-width: 100%;
    margin: 0 auto;
    height: auto !important;
}

.responsivando {
}

.max-responsivando {
    width: 100% !important;
    margin: 0 auto;
    height: auto !important;
}

.escondido {
    display: none;
}

.mostrando {
    display: block;
}

/* Images */
article .alignleft {
    float: left;
}

article .alignright {
    float: right;
}

article .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

article figure.wp-caption.alignleft,
article img.alignleft {
    margin: 5px 20px 5px 0;
}

article .wp-caption.alignleft {
    margin: 5px 10px 5px 0;
}

article figure.wp-caption.alignright,
article img.alignright {
    margin: 5px 0 5px 20px;
}

article .wp-caption.alignright {
    margin: 5px 0 5px 10px;
}

article img.aligncenter {
    margin: 5px auto;
}

article img.alignnone {
    margin: 5px 0;
    width: 100% !important;
    height: auto !important;
}

article .wp-caption .wp-caption-text,
article .entry-caption,
article .gallery-caption {
    color: #220e10;
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    margin: 0 0 24px;
}

article div.wp-caption.alignright img[class*='wp-image-'] {
    float: right;
}

article div.wp-caption.alignright .wp-caption-text {
    padding-left: 10px;
}

div:focus,
a:focus,
div:active,
a:active {
    outline: none;
}

.esconder {
    visibility: hidden !important;
}

.mostrar {
    visibility: visible !important;
}

/*@ wp-pagenavi */
.wp-pagenavi {
    display: block;
    clear: both;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 30px;
    color: #333;
    margin-left: 0px;
    overflow: hidden;
}

.wp-pagenavi a,
.wp-pagenavi span {
    border: none !important;
    border: 1px solid #ddd !important;
    padding: 7px 7px !important;
    min-width: 35px !important;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
}

.wp-pagenavi span.pages {
    border-color: transparent !important;
    font-size: 0.7em !important;
}

.wp-pagenavi a {
    color: #73797f;
}

.wp-pagenavi a:hover {
    color: #fff;
    background-color: #73797f;
}

.wp-pagenavi span {
    color: #73797f;
}

.video {
    padding-bottom: 56.25%;
    padding-top: 0px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.video iframe,
.video object,
.video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
}

/*@ alterando cor da seleção */
::-moz-selection {
    color: #072d1f;
    background: #b2ddce;
}

::selection {
    color: #072d1f;
    background: #b2ddce;
}

/*@ mensagem cf7 */
div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing,
div.wpcf7-mail-sent-ng,
div.wpcf7-aborted,
div.wpcf7-mail-sent-ok {
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 0.85em;
}

.wppninja-gallery .wppninja-spacing {
    margin-bottom: 30px !important;
}

/*@ plugin trip advisor */
#CDSSCROLLINGRAVE.narrow {
    width: 100% !important;
    margin-top: 15px !important;
}

.cdsROW.narrow {
    width: 100% !important;
    margin-top: 30px !important;
    padding: 20px !important;
}

#login-column {
    /* -webkit-box-shadow: 4px 4px 37px -4px rgba(130, 127, 130, 1);
    -moz-box-shadow: 4px 4px 37px -4px rgba(130, 127, 130, 1);
    box-shadow: 4px 4px 37px -4px rgba(130, 127, 130, 1);*/
    padding: 35px;
    background-color: #ffffff;
}

#login-box h1 {
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    font-size: 2em;
    letter-spacing: 7px;
    padding: 35px;
    margin-bottom: 20px;
}

.container-login {
    padding-top: 0px !important;
    height: 100%;
}

#alterar-perfil-visibilidade-column {
    /*-webkit-box-shadow: 4px 4px 37px -4px rgba(130, 127, 130, 1);
    -moz-box-shadow: 4px 4px 37px -4px rgba(130, 127, 130, 1);
    box-shadow: 4px 4px 37px -4px rgba(130, 127, 130, 1);*/
    padding: 35px;
}

#alterar-perfil-visibilidade-row {
    background-color: #ffffff;
    margin-bottom: 6%;
}

#alterar-perfil-visibilidade-box h1 {
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    letter-spacing: 7px;
    padding: 35px;
    margin-bottom: 20px;
}

.alterar-perfil-visibilidade-login {
    padding-top: 0px !important;
}

.demo-container {
    /*-webkit-box-shadow: 4px 4px 37px -4px rgba(130, 127, 130, 1);
    -moz-box-shadow: 4px 4px 37px -4px rgba(130, 127, 130, 1);
    box-shadow: 4px 4px 37px -4px rgba(130, 127, 130, 1);*/
    padding: 35px;
}

.container-grid {
    padding-top: 0px !important;
}

/** The Magic **/
.btn-breadcrumb {
    padding-left: 5px !important;
}

.btn-breadcrumb .btn:not(:last-child):after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 10px solid white;
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: 100%;
    z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 10px solid rgb(173, 173, 173);
    position: absolute;
    top: 50%;
    margin-top: -17px;
    margin-left: 1px;
    left: 100%;
    z-index: 3;
}

/** The Spacing **/
.btn-breadcrumb .btn {
    padding: 6px 12px 6px 24px;
}

.btn-breadcrumb .btn:first-child {
    padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
    padding: 6px 18px 6px 24px;
}

/** Default button **/
.btn-breadcrumb .btn.btn-default:not(:last-child):after {
    border-left: 10px solid #fff;
}

.btn-breadcrumb .btn.btn-default:not(:last-child):before {
    border-left: 10px solid #ccc;
}

.btn-breadcrumb .btn.btn-default:hover:not(:last-child):after {
    border-left: 10px solid #ebebeb;
}

.btn-breadcrumb .btn.btn-default:hover:not(:last-child):before {
    border-left: 10px solid #adadad;
}

/** Primary button **/
.btn-breadcrumb .btn.btn-primary:not(:last-child):after {
    border-left: 10px solid #428bca;
}

.btn-breadcrumb .btn.btn-primary:not(:last-child):before {
    border-left: 10px solid #357ebd;
}

.btn-breadcrumb .btn.btn-primary:hover:not(:last-child):after {
    border-left: 10px solid #3276b1;
}

.btn-breadcrumb .btn.btn-primary:hover:not(:last-child):before {
    border-left: 10px solid #285e8e;
}

/** Success button **/
.btn-breadcrumb .btn.btn-success:not(:last-child):after {
    border-left: 10px solid #5cb85c;
}

.btn-breadcrumb .btn.btn-success:not(:last-child):before {
    border-left: 10px solid #4cae4c;
}

.btn-breadcrumb .btn.btn-success:hover:not(:last-child):after {
    border-left: 10px solid #47a447;
}

.btn-breadcrumb .btn.btn-success:hover:not(:last-child):before {
    border-left: 10px solid #398439;
}

/** Danger button **/
.btn-breadcrumb .btn.btn-danger:not(:last-child):after {
    border-left: 10px solid #d9534f;
}

.btn-breadcrumb .btn.btn-danger:not(:last-child):before {
    border-left: 10px solid #d43f3a;
}

.btn-breadcrumb .btn.btn-danger:hover:not(:last-child):after {
    border-left: 10px solid #d2322d;
}

.btn-breadcrumb .btn.btn-danger:hover:not(:last-child):before {
    border-left: 10px solid #ac2925;
}

/** Warning button **/
.btn-breadcrumb .btn.btn-warning:not(:last-child):after {
    border-left: 10px solid #f0ad4e;
}

.btn-breadcrumb .btn.btn-warning:not(:last-child):before {
    border-left: 10px solid #eea236;
}

.btn-breadcrumb .btn.btn-warning:hover:not(:last-child):after {
    border-left: 10px solid #ed9c28;
}

.btn-breadcrumb .btn.btn-warning:hover:not(:last-child):before {
    border-left: 10px solid #d58512;
}

/** Info button **/
.btn-breadcrumb .btn.btn-info:not(:last-child):after {
    border-left: 10px solid #5bc0de;
}

.btn-breadcrumb .btn.btn-info:not(:last-child):before {
    border-left: 10px solid #46b8da;
}

.btn-breadcrumb .btn.btn-info:hover:not(:last-child):after {
    border-left: 10px solid #39b3d7;
}

.btn-breadcrumb .btn.btn-info:hover:not(:last-child):before {
    border-left: 10px solid #269abc;
}

.breadcrumb-background {
    background-color: #ffffff;
    padding-top: 15px !important;
}

.breadcrumb-title {
    margin-top: 0 !important;
    padding-top: 0 !important;
    font-size: 20px !important;
}

.cliente-ativo {
    float: right;
    margin: 0px !important;
    padding: 0px !important;
    font-weight: 1 !important;
    font-size: 1em;
}

.btn-clientes {
    margin: 20px;
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 1000px) {
    .box-acessar-meus-sistemas {
        margin-bottom: 20px;
    }

    .box-acessar-meus-sistemas small {
        font-size: 0.6em;
        letter-spacing: 0px;
    }

    .site-desc {
        display: none;
    }

    .breadcrumb-title {
        font-size: 0.8em !important;
        font-weight: 0.9 !important;
        float: left;
        order: 2;
    }

    .cliente-ativo {
        float: left;
        margin-top: -50px;
        order: 1;
    }

    .modal.show .modal-dialog {
        width: 100%;
        margin-right: auto !important;
    }

    .modal-content {
        margin-top: auto !important;
    }

    .row-modal {
        width: 120% !important;
    }
}

.panel-heading a:after {
    font-family: 'Glyphicons Halflings';
    content: '\2212';
    float: right;
    color: grey;
}

.panel-heading a.collapsed:after {
    content: '\2b';
}

.panel-heading a:after {
    font-family: 'Glyphicons Halflings';
    content: '\2212';
    float: right;
    color: grey;
}

.panel-heading a.collapsed:after {
    content: '\2b';
}

.panel-heading h4 {
    font-size: 1em;
    font-weight: 1 !important;
    margin-bottom: 20px;
}

.panel-default {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 10px;
}

.checkbox {
    border-top: 1px solid #ddd;
}

.modal.show .modal-dialog {
    float: right;

    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    height: 100% !important;
    position: fixed;
    right: 0;
}

.modal-content {
    height: 100% !important;
    margin-top: -7%;
}

.modal-body {
    overflow-y: auto;
}

.row-modal {
    width: 125%;
}

.modal-title {
    margin-bottom: 10px;
}

.trocar-ambiente {
    color: #fff;
}

.portal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow: hidden;
    background-color: rgb(243, 243, 244);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

