/*@ CSS Document */
body .container-bg {
  font: 400 16px "Open Sans", sans-serif;
  color: #666;
  background: #fff;
}

/*---------------------------- Start S I T E --------------------------*/

a,
a:hover,
a:focus,
a:active {
  color: #166245;
}

/*@ header */
header.header-auth {
  background-color: #166245;
  color: #fff;
}
header.header-auth .container {
  padding-top: 10px;
  padding-bottom: 10px;
}
header.header-auth a,
header.header-auth a:hover,
header.header-auth a:focus,
header.header-auth a:active {
  color: #fff;
}

.form-control.tron-input:focus {
  color: #495057;
  background-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(54, 167, 125, 0.25);
}

header.header-auth .btn {
  font-size: 0.9em;
}

/*@ navbar-brand */
.auth.navbar-brand {
  width: 50px;
  height: 20px;
  background: url(/assets/img/sprite.png) no-repeat -20px -20px;
  margin: 0;
  padding: 0;
}
.auth.navbar-brand span {
  display: none;
}
.auth-site-desc {
  font-size: 0.85em;
  margin-left: 10px;
  margin-top: 5px;
  display: block;
}

/*@ logo-login */
.logo-login {
  width: 120px !important;
  height: auto !important;
  margin: 50px auto 50px auto;
}

/*@ wrap-form-login */
.wrap-form-login {
  display: block;
}
.wrap-form-login label {
  color: #166245;
  font-weight: 700 !important;
}
.wrap-form-login .form-control {
  border-radius: 30px;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}
.wrap-form-login .form-group {
  margin-bottom: 20px;
}
.wrap-form-login .checkbox {
  margin-bottom: 10px;
  padding-top: 10px;
  font-size: 0.85em;
}
.wrap-form-login .checkbox label {
  color: #7d7d7d !important;
  font-weight: 400 !important;
}
.wrap-form-login .checkbox input {
  margin-right: 6px;
  vertical-align: middle;
}

/*@ btn-tron */
.btn-tron {
  font-weight: 500;
  color: #fff;
  background-color: #166245;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}
.btn-tron:hover,
.btn-tron:focus,
.btn-tron:active {
  color: #fff !important;
  background-color: #0c422d !important;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

/*@ links-footer */
ul.links-footer {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;
  text-align: right;
  font-size: 0.85em;
}
ul.links-footer li {
  display: block;
  margin-bottom: 5px;
}

/*@ texto-footer */
.texto-footer {
  color: #c8c8c8;
  font-size: 0.65em;
  text-align: center;
  margin-bottom: 50px;
}

/*@ vert-align-banners */
section.auth.wrap .col-xl-4 {
  background: url(/assets/img/bg-login.jpg) no-repeat left top;
  background-size: cover;
  padding-top: 40px;
  padding-bottom: 40px;
}

/*@ wrap-banner-login */
.wrap-banner-login {
  display: block;
}
.wrap-banner-login:first-child {
  margin-bottom: 40px;
}

/*@ footer */
div.auth {
  color: #999;
  display: block;
}
.auth-copyright {
  padding-top: 10px;
  /* padding-bottom: 10px; */
  font-size: 0.8em;
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  /*@ vert-align-banners */
  section.auth.wrap .col-xl-4 {
    background: none;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  body .container-bg {
    background: url(/assets/img/bg-login.jpg) no-repeat left top;
    background-size: 60% auto;
  }

  .container-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .vert-align-banners {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .vert-align-banners .wrap-banner-login {
  }

  .wrap-form-login {
  }

  .logo-login {
    margin: 100px auto 50px auto;
  }

  /*@ header */
  header.header-auth {
    position: absolute;
    top: 25px;
    left: 0;
    background-color: transparent;
    z-index: 500;
  }

  /*@ footer */
  div.auth {
    color: #fff;
    position: absolute;
    bottom: 0px;
    left: 20px;
    z-index: 500;
  }
}

/*--------------------------------------- Tratamento para telas de alta resolução ---------------------------------------*/

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 120dpi) {
  .auth.navbar-brand {
    background-image: url(/assets/img/sprite@2x.png);
    background-size: 600px 600px;
  }
}

.responsivando {
  max-width: 100%;
  margin: 0 auto;
  height: auto !important;
}
.max-responsivando {
  width: 100% !important;
  margin: 0 auto;
  height: auto !important;
}
