@import "~antd/dist/antd.css";

.validation-errors {
  font-size: 13px;
  color: #d00247;
}

.fs-13 {
  font-size: 13px !important;
}

.progress-small {
  height: 8px;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 10px;
}

/* icons position fix */
.ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
  top: -7px !important;
}

.steps-action {
  margin-top: 24px;
}

.btn-tron-ghost {
  font-weight: 600;
  color: #166245 !important;
  background-color: transparent;
  border-radius: 20px;
  font-size: 0.9em;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: All 0.3s ease;
  border: 1px solid #166245;
}

span.nav-link {
  font-size: 14.4px;
}

.bg-transparent {
  background-color: transparent !important;
}

.loading-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.small-loading-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #2d7157;
}

.button-distance {
  display: flex;
  justify-content: space-around;
}

.m-t-sm {
  margin-top: 10px;
}

.card-box {
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 5px;
  cursor: pointer;
}

.card-box-no-pointer {
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 5px;
}

.card-box-default {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
}

.card-box-danger {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px #dc3545;
}

.m-b-xs {
  margin-bottom: 5px;
}

#chart {
  height: 440px;
  width: 100%;
}

#chart.pointer-on-bars .dxc-series rect {
  cursor: pointer;
}

.button-container {
  text-align: center;
  height: 34px;
  position: absolute;
  top: 30px;
  left: 15px;
}